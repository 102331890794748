@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-control {
  background-color: #FFF;
}

.image.flag {
  height: 1em;
  bottom: 0.1em;
  vertical-align: middle !important;
}

.draggable-card-container {
  width: calc(33.33333% - 2em);
  margin: 0.875em 0.5em;
  margin-left: 1em;
  margin-right: 1em;
}


.App-control {
  padding-top: 220px;
  padding-bottom: 4.4rem;
}


@media (max-width: 1200px) {
  /* CSS that should be displayed if width is equal to or less than 800px goes here */

  .draggable-card-container {
    width: calc(50% - 2em);
    margin: 0.875em 0.5em;
    margin-left: 1em;
    margin-right: 1em;
  }
}

@media (max-width: 600px) {
  /* CSS that should be displayed if width is equal to or less than 800px goes here */

  .draggable-card-container {
    width: calc(100% - 2em);
    margin: 0.875em 0.5em;
    margin-left: 1em;
    margin-right: 1em;
  }


  .App-control {
    padding-top: 100px;
    padding-bottom: 4.4rem;
  }

}

.draggable-card-container>.card {
  width: 100% !important;
}

.Sidebar {
  background-color: white;
  height: 100vh;
  padding: 56px;
  display: flex;
  flex-direction: column;
}

.Sidebar>.Indicatie-property-container>.Indicatie-property {
  padding-right: 32px;
  padding-top: 16px;
  font-size: 24px;
  float: left;
  font-weight: 600;
}


.Sidebar>.Total-property-container>.Total-property {
  float: right;
  text-align: right;
  margin-top: 56px;
  font-size: 24px;
}

.Sidebar>.Total-property-container>.Total-property>label {
  display: block;
  color: grey;
  font-size: 14px;
  margin-bottom: 12px;
}

.ui.table tr td {
  border-top: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 8px !important;
}

.Sidebar>.Indicatie-property-container>.Indicatie-property>label {
  display: block;
  color: grey;
  font-size: 14px;
  margin-bottom: 12px;
}

.Sidebar>.Indicatie-delete {
  clear: both;
  margin-top: auto;
  align-self: center;
  width: 240px;
}

.overlay {
  background-color: #fff;
}

.Sidebar-table {
  clear: both;
  padding-top: 56px;
  font-size: 18px;
}

img.twemoji {
  height: 1.9em;
  width: auto;
  display: inline-block;
  margin-right: 14px;
}

.App-subheader {
  padding-top: 3.8rem;
}

.App-footer,
.App-footer>a {
  color: #C0C0C2 !important;
  padding-top: 3.8rem;
  text-align: center;
}

h3.no-indications {
  color: #C0C0C2 !important;
  font-style: italic;
  font-size: 20px !important;
}

h4.header>div {
  display: flex;
  align-items: center;
}

h4.header {
  font-weight: 700;
  font-size: 20px;
}

h4.header.overflow {
  overflow: hidden;
  /* make sure it hides the content that overflows */
  white-space: nowrap;
  /* don't break the line */
  text-overflow: ellipsis;
  /* give the beautiful '...' effect */

}

h4.header>.subheader {
  display: block;
  color: #C0C0C2;
  font-size: 14px;
  font-weight: 400;
}

a.card {
  color: #15131F !important;
}


.cardheader {
  padding: 0 !important;
  padding-top: 0.5em !important;
}

.cardheader>.column {
  margin-bottom: 0 !important;
}

.draggable-card-container {
  touch-action: none;
}